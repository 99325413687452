<template>
  <div class="login_wrapper">
    <LoadingIcon v-show="1" />
  </div>
</template>

<script>
  import Vue from "vue";
  import VueTheMask from "vue-the-mask";
  import { mapActions, mapGetters } from "vuex";
  import LoadingIcon from "@/components/LoadingIcon.vue";

  Vue.use(VueTheMask);
  export default {
    components: {
      LoadingIcon,
    },
    name: "LoginPage",
    data() {
      return {
        show_msg: false,
        message: "",
        show: true,
        checked: false,
        form: {
          email: "",
          password: "",
        },
        validateForm: {
          errorM: false,
          errorP: false,
          errorPassword: "",
        },
        sign: {
          document: "",
          company: "",
          email: "",
          password: "",
          confirmPassword: "",
          name: "",
          errorMail: false,
          errorPassword: false,
          errorDocument: false,
          errorCompany: false,
          errorName: false,
          errorMsg: "",
        },
      };
    },
    computed: {
      ...mapGetters({
        isAuthenticated: "isAuthenticated",
      }),
    },
    async created() {
      const queryString = decodeURI(window.location.search);
      const urlParams = new URLSearchParams(queryString);
    
      const tk = await this.$CryptoJS.AES.decrypt((urlParams.get('tk').toString()).toString(), "cMq0#_NcIv66oWNY3e8jXXKWRivia").toString(this.$CryptoJS.enc.Utf8)
      const doc_dep = await this.$CryptoJS.AES.decrypt((urlParams.get('data').toString()).toString(), "cMq0#_NcIv66oWNY3e8jXXKWRivia").toString(this.$CryptoJS.enc.Utf8)
      const name_dep = await this.$CryptoJS.AES.decrypt((urlParams.get('nam').toString()).toString(), "cMq0#_NcIv66oWNY3e8jXXKWRivia").toString(this.$CryptoJS.enc.Utf8)
      const region = await this.$CryptoJS.AES.decrypt((urlParams.get('region').toString()).toString(), "cMq0#_NcIv66oWNY3e8jXXKWRivia").toString(this.$CryptoJS.enc.Utf8)
      const uid = await this.$CryptoJS.AES.decrypt((urlParams.get('uid').toString()).toString(), "cMq0#_NcIv66oWNY3e8jXXKWRivia").toString(this.$CryptoJS.enc.Utf8)
      
      this.driver({ doc: doc_dep, nam: name_dep, tk:tk, region: region, uid:uid })
    },
    methods: {
      ...mapActions({
        login: "login",
        driver: "driver",
        signin: "signIn",
      }),
    },

  };
</script>

<style scoped>
  .login_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 80%;
    height: 100vh;
    margin: 0 auto;
  }
  .login_wrapper .alert {
    position: fixed;
    top: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .login_wrapper img {
    margin-top: 90px;
    height: clamp(70px, 7.16vw, 120px);
  }
  .login_wrapper > h1 {
    margin: 0;
    font-size: 17px;
    font-weight: lighter;
  }
  #submit-btn {
    padding: 7px 29px;
    font-size: 16px;
    letter-spacing: 0px;
    color: var(--light);
    background-color: var(--red);
    border: none;
    transition: all 0.3s ease-in-out;
    margin-bottom: 30px;
  }
  #submit-btn:hover {
    opacity: 0.8;
  }
  #sign_btn {
    color: var(--red);
    border: none;
    background: none;
    transition: all 0.3s ease-in-out;
  }
  #sign_btn:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: 1100px) {
    .login_wrapper > h1 {
      width: clamp(250px, 25vw, 500px);
    }
  }
</style>
