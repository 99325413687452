import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import userService from "../services/userService";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Cadastro de material
    material_checked: false,
    register_material: "",
    description_material: "",
    // Materiais e info do usuário
    document: sessionStorage.getItem("document"),
    region: sessionStorage.getItem("region"),
    uid: sessionStorage.getItem("uid"),
    bag_number: "",
    bag_type: "",
    register_date: "",
    type_bo: "",
    img_bo: "",
    materials: [],
    search: "",
    transfered_materials: "",
    token_login: sessionStorage.getItem("tokenLogin"),
    refreshToken: sessionStorage.getItem("token"),
    user: {},
    // Para transferência de material
    receiver_id: "",
    receiver_id_type: "",
    is_checked: false,
    receiver_name: "",
    // Renderização de passo a passo
    step_number: 1,
    step_stole: 1,
    step_register: 1,
    // Se possui ou não material e renderizar de acordo
    hasMaterials: "waiting",
    // Token de autenticação para upload aws
    token: ""
    //  "eyJraWQiOiJlNjQ1ZmFkYy00ZWY5LTExZTktODY0Ny1kNjYzYmQ4NzNkOTMiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJhNDE4MDY3MS0xMjNkLTQxNzMtOGMxZS1hYzAwNGYwNDgyMjYiLCJBQ0NPVU5UX0lEIjoiYTQxODA2NzEtMTIzZC00MTczLThjMWUtYWMwMDRmMDQ4MjI2IiwiQVBQTElDQVRJT04iOiJGTEVFVCIsInJvbGVzIjpbXSwiaXNzIjoiaUZvb2QiLCJ0eXBlIjoiYWNjZXNzX3Rva2VuIiwiVEVOQU5UIjoiQlIiLCJBVVRIX0NSRURFTlRJQUwiOiJmNjJjMGVjZTg2YTQ0NmI4NGEwNTVhZjlmZGMxNWY5NmFjNjA2ZGNmM2VlMTRhMmZhYTJmN2I3ZDQ2YzE5MjNhZDNjMjJlY2QwMmEyYTQzMmUyNWUxZmRhZWI3ZDNhNzhmYTY4ODAzODQwNjVkNzljNTJhZDhmYTJmMGViMGY4MyIsInByb3ZpZGVyIjoibG9naXN0aWNzLWlkZW50aXR5IiwiVVNFUl9UWVBFIjoiRFJJVkVSIiwiZG9tYWluIjoicml2aWFkZXYuY29tLmJyIiwiRFJJVkVSX0lEIjoiZjA2ODAxM2MtMzVhOS00ZGRmLTgwNzItNjlmYTE3MTBlMGQ5IiwiZXhwIjoxNjU2NjU1MjAwLCJpYXQiOjE2NTY2MDI5MTksInVzZXIiOiJmNjJjMGVjZTg2YTQ0NmI4NGEwNTVhZjlmZGMxNWY5NmFjNjA2ZGNmM2VlMTRhMmZhYTJmN2I3ZDQ2YzE5MjNhZDNjMjJlY2QwMmEyYTQzMmUyNWUxZmRhZWI3ZDNhNzhmYTY4ODAzODQwNjVkNzljNTJhZDhmYTJmMGViMGY4MyJ9.VY8xsCX9SeGnSnIGEuaw2QtBNiOO0Wb2GWy1vGAW1TQsLUbndAtq7Jw9ifXXM2bGQLvStRK9BYwZf4pn_qGy65uFjK-QMPPPgycpu8rAT5QZoULpCJavS7bqx00A-G1BXM5b5AHtVf0vB0p-bBNe2_2c__N0UnUD9LfBi8JVNkg",
  },
  mutations: {
    setMaterialChecked(state, payload) {
      state.material_checked = payload;
    },
    setRegisterMaterial(state, payload) {
      state.register_material = payload;
    },
    setDescriptionMaterial(state, payload) {
      state.description_material = payload;
    },
    setIsChecked(state, payload) {
      state.is_checked = payload;
    },
    setBagNumber(state, payload) {
      state.bag_number = payload;
    },
    setRegisterDate(state, payload) {
      state.register_date = payload;
    },
    setBagType(state, payload) {
      state.bag_type = payload;
    },
    setImgBO(state, payload) {
      state.img_bo = payload;
    },
    setTypeBO(state, payload) {
      state.type_bo = payload;
    },
    setStepNumber(state, payload) {
      state.step_number = payload;
    },
    setStepRegister(state, payload) {
      state.step_register = payload;
    },
    setStepStole(state, payload) {
      state.step_stole = payload;
    },
    setReceiverId(state, payload) {
      state.receiver_id = payload.value;
      state.receiver_id_type = payload.type;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setHasMaterials(state, payload) {
      state.hasMaterials = payload;
    },
    setMaterials(state, payload) {
      state.materials = payload;
    },
    setSearch(state, payload) {
      state.search = payload;
    },
    setTransferedMaterials(state, payload) {
      state.transfered_materials = payload;
    },
    setReceiverName(state, payload) {
      state.receiver_name = payload;
    },
    setDocument(state, payload) {
      state.document = payload;
    },
    SET_REFRESH_TOKEN(state, payload) {
      state.refreshToken = payload;
    },
    SET_TOKEN(state, payload) {
      state.token_login = payload;
    },
    SET_AUTH_USER(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    async driver({ commit }, payload) {
      try {
        //const { data } = await userService.loginIfood(payload);
        //const { data } = await userService.driverLogin('6d095bdb-6736-46ef-abcc-d543651dcd9d');
        
        //commit("SET_AUTH_USER", data.user);
        //console.log(payload)
        commit("SET_TOKEN", payload.tk);
        //commit("SET_REFRESH_TOKEN", data.refreshToken);
        commit("setDocument", payload.doc);
        //sessionStorage.setItem("id", data.id);
        sessionStorage.setItem("user", payload.nam);
        sessionStorage.setItem("mobile", 1);
        sessionStorage.setItem("document", payload.doc);
        sessionStorage.setItem("tokenLogin",  payload.tk);
        sessionStorage.setItem("region", payload.region);
        sessionStorage.setItem("uid",  payload.uid);
        window.location.href = '/meus-materiais'
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async login({ commit }, payload) {
      try {
        const { data } = await userService.loginUser(payload);
        commit("SET_AUTH_USER", data.user);
        commit("SET_TOKEN", data.token);
        commit("SET_REFRESH_TOKEN", data.refreshToken);
        commit("setDocument", data.user.document);
        sessionStorage.setItem("user", data.user.name);
        sessionStorage.setItem("token", data.refreshToken);
        sessionStorage.setItem("document", data.user.document);
        sessionStorage.setItem("tokenLogin", data.idToken);
        sessionStorage.setItem("region", data.user.city);
        sessionStorage.setItem("uid", data.user.document);
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async signIn({ commit }, payload) {
      try {
        const { data } = await userService.signInUser(payload);
        commit("SET_AUTH_USER", {});
        return data.message;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async searchZip({ commit }, payload) {
      try {
        const { data } = await userService.zip(payload);
        commit("SET_AUTH_USER", {});
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    logout() {
      sessionStorage.clear();
      router.push("/");
      window.location.reload();
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return state.token_login === null && state.refreshToken === null
        ? false
        : true;
    },
  },
});
