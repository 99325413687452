<template>
  <div class="login_wrapper">
    <div class="alert alert-secondary fade show" role="alert" v-if="show_msg">
      {{ message }}
    </div>

    <img src="@/assets/img/logo_ifood.png" alt="Logotipo do ifood materiais" />
    <h1>
      {{
        show
          ? "Insira suas informações de cadastro para fazer login."
          : "Insira suas informações para realizar o cadastro"
      }}
    </h1>

    <b-form @submit.prevent.stop="onLogIn" v-if="show">
      <InputForm
        label="E-mail"
        id="input_email"
        type="email"
        v-model="form.email"
        :error="validateForm.errorM"
      />
      <InputForm
        label="Senha"
        id="input_password"
        type="password"
        v-model="form.password"
        :description="validateForm.errorPassword"
        :error="validateForm.errorP"
      />
      <b-button id="submit-btn" type="submit" variant="primary"
        >Entrar</b-button
      >
    </b-form>
    <b-form @submit.prevent.stop="onSignin" v-if="!show">
      <InputForm
        label="Nome completo"
        id="input_name"
        type="text"
        v-model="sign.name"
        :error="sign.errorName"
      />
      <InputForm
        label="E-mail"
        id="input_email"
        type="email"
        v-model="sign.email"
        :error="sign.errorMail"
      />
      <InputForm
        label="Criar senha"
        id="input_password"
        type="password"
        v-model="sign.password"
        :error="sign.errorPassword"
        :description="sign.errorMsg"
      />
      <InputForm
        label="Confirmar senha"
        id="input_confirm"
        type="password"
        v-model="sign.confirmPassword"
        :error="sign.errorPassword"
      />
      <InputForm
        label="Nome da empresa"
        id="input_company"
        type="text"
        v-model="sign.company"
        :error="sign.errorCompany"
      />
      <InputForm
        label="CNPJ"
        id="input_document"
        type="text"
        v-mask="'##.###.###/####-##'"
        v-model="sign.document"
        :error="sign.errorDocument"
      />
      <InputForm
        label="CEP"
        id="input_document"
        type="text"
        v-mask="'#####-###'"
        v-model="sign.zipCode"
      />

      <b-button id="submit-btn" type="submit" variant="primary"
        >Cadastrar</b-button
      >
    </b-form>
    <div v-show="show">
      Não possui cadastro?
      <button type="button" id="sign_btn" @keydown="signIn" @click="signIn">
        Cadastre-se
      </button>
    </div>
  </div>
</template>

<script>
import InputForm from "@/components/form/InputForm.vue";
import Vue from "vue";
import VueTheMask from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";

Vue.use(VueTheMask);
export default {
  name: "LoginPage",
  data() {
    return {
      show_msg: false,
      message: "",
      show: true,
      checked: false,
      form: {
        email: "",
        password: "",
      },
      validateForm: {
        errorM: false,
        errorP: false,
        errorPassword: "",
      },
      sign: {
        document: "",
        company: "",
        email: "",
        password: "",
        confirmPassword: "",
        name: "",
        errorMail: false,
        errorPassword: false,
        errorDocument: false,
        errorCompany: false,
        errorName: false,
        errorMsg: "",
        zipCode: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
    }),
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tk = urlParams.get("tk");
    if (tk) {
      console.log("login", tk);
      this.driver(tk);
    }
  },
  methods: {
    ...mapActions({
      login: "login",
      driver: "driver",
      signin: "signIn",
      searchZip: "searchZip",
    }),
    async onLogIn() {
      sessionStorage.clear();
      const data = {
        username: this.form.email,
        password: this.form.password,
      };
      this.checked = true;
      await this.login(data).then(() => {
        console.log("asd", this.isAuthenticated);
        if (this.isAuthenticated) {
          this.$router.push("/meus-materiais");
        } else {
          this.validateForm.errorM = true;
          this.validateForm.errorP = true;
          this.validateForm.errorPassword = "Usuário ou senha inválidos";
          setTimeout(() => {
            this.validateForm.errorPassword = "";
            this.validateForm.errorM = false;
            this.validateForm.errorP = false;
          }, 8000);
        }
      });
    },
    async onSignin() {
      
      if (!this.checkPassword()) {
        this.sign.errorPassword = true;
        this.sign.errorMsg =
          "Senha deve conter no mínimo 8 caracteres, letra maiúscula, letra minúscula, número e caractere especial";
        setTimeout(() => {
          this.sign.errorMsg = "";
          this.sign.errorPassword = false;
        }, 8000);
      } else if (this.sign.confirmPassword != this.sign.password) {
        this.sign.errorPassword = true;
        this.sign.errorMsg = "As senhas devem ser iguais";
        setTimeout(() => {
          this.sign.errorMsg = "";
          this.sign.errorPassword = false;
        }, 8000);
      } else {
        this.checked = true;
        await this.buscaCep()

        const data = {
        name: this.sign.name,
        corporate_name: this.sign.company,
        document: this.sign.document,
        email: this.sign.email,
        password: this.sign.password,
        city: this.sign.city,
        zipCode: this.sign.zipCode,
      };

        await this.signin(data).then((res) => {
          this.message = res + "!";
          this.show_msg = true;
          setTimeout(() => {
            this.show_msg = false;
            window.location.reload();
          }, 3000);
        });
      }
    },
    async buscaCep() {
      if (this.sign.zipCode.length == 9) {
        var response = await this.searchZip({
          zip: this.sign.zipCode.replace("-", ""),
        });
        console.log(response)
        if (response.erro) {
          console.log("erro");
        } else {
          this.address =
            response.logradouro +
            ", " +
            response.bairro +
            ", " +
            response.localidade +
            ", " +
            response.uf;
          this.sign.city = response.localidade;
          this.sign.neighborhood = response.bairro;
          this.sign.state = response.uf;
          this.sign.street = response.logradouro;
          console.log(this.sign.street)
        }
      }
    },
    signIn() {
      this.show = false;
    },
    checkPassword() {
      const regex =
        /^(?=.*\d)(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#!=+.-])[0-9a-zA-Z$*&@#!=+.-]{8,}$/;
      return regex.test(this.sign.password);
    },
  },

  components: { InputForm },
};
</script>

<style scoped>
.login_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 80%;
  height: 100vh;
  margin: 0 auto;
}
.login_wrapper .alert {
  position: fixed;
  top: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.login_wrapper img {
  margin-top: 90px;
  height: clamp(70px, 7.16vw, 120px);
}
.login_wrapper > h1 {
  margin: 0;
  font-size: 17px;
  font-weight: lighter;
}
#submit-btn {
  padding: 7px 29px;
  font-size: 16px;
  letter-spacing: 0px;
  color: var(--light);
  background-color: var(--red);
  border: none;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
}
#submit-btn:hover {
  opacity: 0.8;
}
#sign_btn {
  color: var(--red);
  border: none;
  background: none;
  transition: all 0.3s ease-in-out;
}
#sign_btn:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1100px) {
  .login_wrapper > h1 {
    width: clamp(250px, 25vw, 500px);
  }
}
</style>
