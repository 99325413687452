import api from "../configs/axios-api";
import axios from "axios";

const loginUser = (payload) => api.post(`/login`, { ...payload });
const signInUser = (payload) => api.post(`/user`, { ...payload });//, { headers: { authorization: tokenLogin }}
const driverLogin = (payload) => axios.get('https://logistics-api.ifood-sandbox.com.br/driver/benefits/v1/drivers/' + payload, {headers: { "X-API-Key": 'pw381w4teldq09tw0j23zbtkn4zit95z3e4vds06b4wnvw3qtqlddrmgj6hpuiylmq1fsaewa57evlznxweq44qst42fcm9k6fzdhx966axs1e9qwtrjocygvzalz2mg', 'X-Origin-Identifier': 'f437a7cb-f7e4-401a-810b-9be155cf211f'}})
const loginIfood = (payload) => axios.get(`https://fleet-api.ifood.com.br/api/logistics/crm-identity/worker`, {  headers: { Authorization: `Bearer ${payload}` },})
const zip = (payload) => axios.get(`https://viacep.com.br/ws/${payload.zip}/json/`, {  headers: { Authorization: `Bearer ${payload}` },})


export default {
  loginUser,
  signInUser,
  loginIfood,
  driverLogin,
  zip
};
