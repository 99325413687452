<template>
  <div class="loader_container">
    <img src="@/assets/icons/loading.svg" alt="Ícone de carregamento" />
  </div>
</template>

<script>
  export default {
    name: "LoadingIcon",
  };
</script>

<style>
  .loader_container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 100%;
    height: 100%;
  }

  .loader_container > img {
    width: 10%;
  }
</style>
