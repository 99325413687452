import Vue from "vue";
import App from "./App.vue";
import api from "./configs/axios-api";
import "./plugins/bootstrap-vue";
import "./plugins/loading-overlay";
import router from "./router";
import store from "./store";
import VueCryptojs from 'vue-cryptojs';
//import axios from "axios";

Vue.prototype.$http = api;
Vue.config.productionTip = false;

Vue.use(VueCryptojs)

/*window.loadDriverJWT = async function (token, workerId) {
  
  sessionStorage.setItem("mobile", 1)
  sessionStorage.setItem("worker_id", workerId)
   try {
     await axios.get(
       `https://fleet-api.ifood.com.br/api/logistics/crm-identity/worker`,
       {
         headers: { Authorization: `Bearer ${token}` },
       }
     )
       .then((response) => {
         const { id, name, cpf } = response.data;
         sessionStorage.setItem("id", id);
         sessionStorage.setItem("user", name);
         sessionStorage.setItem("document", cpf);
         sessionStorage.setItem("tokenLogin", token);
         window.location.href = '/meus-materiais'
         new Vue({
           router,
           render: (h) => h(App),
         }).$mount('#app')
       });
   } catch (err) {
     console.error(err);
   }
 }*/

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
