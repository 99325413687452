import axios from "axios";

let baseURL = 'https://' + location.host + '/v1'

console.log('Hostname', process.env.VUE_APP_HOSTNAME)
const hostname = process.env.VUE_APP_HOSTNAME

if (hostname) baseURL = hostname + '/v1'

const api = axios.create({
  // Criar
  baseURL: baseURL, //"https://ka9ionraa7.execute-api.us-east-1.amazonaws.com/dev/v1", //url base da sua api
  timeout: 10000, //tempo máximo de espera de resposta,
  FormData: true, //permite enviar arquivos

  headers: {
    "Content-Type": "application/json",
    Authorization: "eyJraWQiOiJlNjQ1ZmFkYy00ZWY5LTExZTktODY0Ny1kNjYzYmQ4NzNkOTMiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJhNDE4MDY3MS0xMjNkLTQxNzMtOGMxZS1hYzAwNGYwNDgyMjYiLCJBQ0NPVU5UX0lEIjoiYTQxODA2NzEtMTIzZC00MTczLThjMWUtYWMwMDRmMDQ4MjI2IiwiQVBQTElDQVRJT04iOiJGTEVFVCIsInJvbGVzIjpbXSwiaXNzIjoiaUZvb2QiLCJ0eXBlIjoiYWNjZXNzX3Rva2VuIiwiVEVOQU5UIjoiQlIiLCJBVVRIX0NSRURFTlRJQUwiOiJmNjJjMGVjZTg2YTQ0NmI4NGEwNTVhZjlmZGMxNWY5NmFjNjA2ZGNmM2VlMTRhMmZhYTJmN2I3ZDQ2YzE5MjNhZDNjMjJlY2QwMmEyYTQzMmUyNWUxZmRhZWI3ZDNhNzhmYTY4ODAzODQwNjVkNzljNTJhZDhmYTJmMGViMGY4MyIsInByb3ZpZGVyIjoibG9naXN0aWNzLWlkZW50aXR5IiwiVVNFUl9UWVBFIjoiRFJJVkVSIiwiZG9tYWluIjoicml2aWFkZXYuY29tLmJyIiwiRFJJVkVSX0lEIjoiZjA2ODAxM2MtMzVhOS00ZGRmLTgwNzItNjlmYTE3MTBlMGQ5IiwiZXhwIjoxNjU2MTM2ODAwLCJpYXQiOjE2NTYxMTEzMDQsInVzZXIiOiJmNjJjMGVjZTg2YTQ0NmI4NGEwNTVhZjlmZGMxNWY5NmFjNjA2ZGNmM2VlMTRhMmZhYTJmN2I3ZDQ2YzE5MjNhZDNjMjJlY2QwMmEyYTQzMmUyNWUxZmRhZWI3ZDNhNzhmYTY4ODAzODQwNjVkNzljNTJhZDhmYTJmMGViMGY4MyJ9.QvkAKBA8ZYDLVsLBHbLK4-_VofFYVuptLlMK6WtJkws_XNwE9ISN8HFKeC9dp2JCqFm_JkW7_cUGOCVa3U76HxxiWryj7G-5r3eOcGG7Zzq1SwrgzSqVL-aNBiu2xm4obbBztIz0LMhkPeLc8qZ5JfwnGsQhFRPF7GKosL6oOWo",
  },
});

export default api;
