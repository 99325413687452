<template>
  <b-form-group
    id="input-group"
    :label="label"
    :label-for="id"
    :description="description"
  >
    <b-form-input
      :id="id"
      :type="type"
      :class="`form-control ${error ? 'error' : ''}`"
      :placeholder="placeholder"
      required
      @input="$emit('input', $event)"
      :maxlength="maxlength"
    />
  </b-form-group>
</template>

<script>
  export default {
    name: "InputForm",
    props: {
      label: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      error: {
        type: Boolean,
        default: false,
      },
      maxlength: {
        type: Number,
        default: null,
      },
    },
  };
</script>

<style>
  #input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: clamp(16px, 1.15vw, 18px);
    font-weight: lighter;
  }
  .text-muted {
    color: var(--red) !important;
    width: clamp(300px, 35vw, 520px);
    text-align: left;
  }
  .form-control {
    width: clamp(300px, 35vw, 520px);
    height: 37px;
    background-color: var(--light-dark);
    border: none;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .form-control:focus {
    box-shadow: 0 0 0 0.2rem var(--light-dark);
  }
  .form-control.error {
    border: 1px solid var(--red);
    color: var(--red);
  }
  .form-control.error:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
  }
  #input_company.form-control {
    text-transform: uppercase;
  }
  #input_name.form-control {
    text-transform: capitalize;
  }
  @media screen and (max-width: 340px) {
    .form-control {
      width: 85vw;
    }
  }
</style>
